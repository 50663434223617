.player {
    @media (min-width: 768px) {
        text-align: start !important;
    }

    a, .current {
        text-transform: capitalize;
    }

    color:white;
    .stations {
        font-size: 25px;
    }

    .background {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;

        .effect {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0);
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);
            z-index: 1;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            filter: blur(5px);
        }
    }

    .station {
        height: 180px;
        width: 180px;
        img {
            height: 100%;
            width: 100%;
        }
    }

    .content {
        color: white;

        .data {
            padding-left: 0;
            padding-right: 0;
            overflow-x: hidden;
        }
    }

    .playpause {
        transition: transform 5s;
        :hover {
            transform: scale(1.3);
        }
        label {
            display: block;
            box-sizing: border-box;
            width: 0;
            height: 74px;

            border-color: transparent transparent transparent white;
            transition: 100ms all ease;
            cursor: pointer;

            border-style: solid;
            border-width: 37px 0 37px 60px;
        }
    }

    .playpause.playing label {
        border-style: double;
        border-width: 0px 0 0px 60px;
    }
}
